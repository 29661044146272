/* globals $ */

import './lang-switch'
import './menu'
import './filter-line'
import './load-more'
import './home-logo'
import './contrast-checker'

$(function () {
  // used to kick off all scroll event handlers
  $(window).trigger('scroll')
})
